import React, { useState } from "react";

import { Layout } from "./index";
import UnorderedList from "~/components/List/Unordered";
import {
  AboutTheOrgModal,
  ContactInformationModal,
  InformationModalOpener,
} from "~/components/modals";

const ContactUsPage = (): JSX.Element => {
  const [aboutTheOrgModalVisible, setAboutTheOrgModalVisible] = useState<boolean>(false);
  const [aboutTheOrgModalRating, setAboutTheOrgModalRating] = useState<number>(-1);
  const [contactInformationModalVisible, setContactInformationModalVisible] = useState<boolean>(false);
  const [contactInformationModalRating, setContactInformationModalRating] = useState<number>(-1);

  return (
    <>
      <Layout>
        <div className="grid justify-items-center">
          <div className="max-w-4xl text-lg leading-tight">
            <InformationModalOpener
              rating={aboutTheOrgModalRating}
              onClick={() => setAboutTheOrgModalVisible(true)}
            />

            <h3 className="mt-5 text-3xl md:mt-0">The American Institute for Lung Health</h3>

            <p>The American Institute for Lung Heath is a federal agency working to improve lung health through research and education. Our work is focused in five areas:</p>
            <UnorderedList className="ml-14 list-disc">
              <li>Preventing and treating lung cancer</li>
              <li>Treating cystic fibrosis</li>
              <li>Managing asthma</li>
              <li>Promoting clean air</li>
              <li>Education</li>
            </UnorderedList>
            <p>The work of the institute is guided by a scientific advisory committee that is composed of doctors and research with specific expertise in lung health.</p>

            <h3 className="mt-8 text-3xl">Scientific Advisory Committee</h3>
            <UnorderedList className="list-inside list-disc">
              <li>Marcus Gottlieb, MD Professor Emeritus</li>
              <li>Beverley Wainscot, MD MPH, Professor of Medicine and Pediatrics</li>
              <li>Ang Lui, MD, Professor of Medicine and Program Director.</li>
              <li>Brian V. Spathe, MD, President and CEO</li>
            </UnorderedList>

            <div className="flex mt-8 mb-12">
              <p className="font-bold">You can contact the institute either by email (lung@usa.gov) or by phone 800-555-2121.</p>
              <InformationModalOpener
                className="ml-8"
                rating={contactInformationModalRating}
                onClick={() => setContactInformationModalVisible(true)}
              />
            </div>
          </div>
        </div>
      </Layout>
      {aboutTheOrgModalVisible && (
        <AboutTheOrgModal
          modalRating={aboutTheOrgModalRating}
          modalVisible={aboutTheOrgModalVisible}
          setModalRating={setAboutTheOrgModalRating}
          setModalVisible={setAboutTheOrgModalVisible}
        />
      )}
      {contactInformationModalVisible && (
        <ContactInformationModal
          modalRating={contactInformationModalRating}
          modalVisible={contactInformationModalVisible}
          setModalRating={setContactInformationModalRating}
          setModalVisible={setContactInformationModalVisible}
        />
      )}
    </>
  );
}

export default ContactUsPage;
